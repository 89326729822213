var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{ref:"formLogin",staticClass:"user-layout-login",attrs:{"id":"formLogin","form":_vm.form}},[(_vm.type === 'reLogin')?_c('a-alert',{staticClass:"m-b-24",attrs:{"type":"error","message":"登录凭证已失效，请重新登录。","banner":""}}):_vm._e(),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'username',
                      {rules: [{ required: true, message: '请输入帐户名或邮箱地址' }], validateTrigger: 'change'}
                    ]),expression:"[\n                      'username',\n                      {rules: [{ required: true, message: '请输入帐户名或邮箱地址' }], validateTrigger: 'change'}\n                    ]"}],attrs:{"size":"large","type":"text","placeholder":"帐户名或邮箱地址"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'password',
                      {rules: [{ required: true, message: '请输入密码' }], validateTrigger: 'blur'}
                    ]),expression:"[\n                      'password',\n                      {rules: [{ required: true, message: '请输入密码' }], validateTrigger: 'blur'}\n                    ]"}],attrs:{"size":"large","type":"password","autocomplete":"false","placeholder":"密码"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',{staticClass:"remember-me"},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['rememberMe',{initialValue:true,valuePropName:'checked'}]),expression:"['rememberMe',{initialValue:true,valuePropName:'checked'}]"}]},[_vm._v("自动登陆")])],1),_c('a-form-item',{staticClass:"m-b-none"},[_c('a-button',{staticClass:"login-button",attrs:{"block":"","size":"large","type":"primary","htmlType":"submit","loading":_vm.loginStatus},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v("登录 ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }